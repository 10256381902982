import axios from 'axios'
import ElementUI from "element-ui";
import {serverPath} from "@/utils/config";

const request = axios.create({
    // 请求的地址
    baseURL: serverPath,
    // 超时时限
    timeout: 19000

})
request.defaults.headers['referrer'] = "1r"

// request 拦截器
request.interceptors.request.use(config => {
    // 设置请求头
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let uuid = localStorage.getItem("uuid")
    if (uuid) {
        config.headers['uuid'] = localStorage.getItem("uuid")
    }

    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    (response) => {
        let res = response.data;
        let uuid = response.headers['uuid']
        if (uuid){
            localStorage.setItem("uuid", uuid)
        }
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        // 当权限验证不通过的时候给出提示
        if (res.code === '401') {
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default request
