import Vue from 'vue'
import App from './App.vue'
import request from '@/utils/request'
import router from '@/router'
import store from '@/store'
import {VueJsonp}  from 'vue-jsonp'



//引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//时间处理器
import './utils/dateTimeFormatUtils.js'
import './assets/css/md.css'
import './assets/css/prism.css'
import './assets/css/aplayer.css'
import './assets/css/solid.css'
import './assets/css/regular.css'
import './assets/css/fontawesome.css'
import './utils/directive'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.config.productionTip = false

Vue.prototype.$request = request


Vue.use(ElementUI,{size:"small"});
Vue.use(VueJsonp)
Vue.use(VueViewer)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

