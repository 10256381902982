import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        siteInfo: {
            baseInfo: {
                beian: "",
                blogName: "",
                commentAdminFlag: "",
                copyright: {
                    title: "",
                    siteName: ""
                },
                footerImgTitle: "",
                footerImgUrl: "",
                reward: "",
                webTitleSuffix: '',
            },

        },
        // 头部信息
        headerInfo: "",
        //文字渲染状态
        renderOver: false,
        navVisible: false,

    },
    mutations: {
        setHeaderInfo(state, headerInfo) {
            state.headerInfo = headerInfo
        },
        setRenderOver(state, boolean) {
            state.renderOver = boolean
        },
        setNavVisible(state, boolean) {
            state.navVisible = boolean
        },
        setSiteInfo(state, siteInfo){
            state.siteInfo = siteInfo
        }
    }
})

export default store
