import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/Index'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/view/Home'),
        meta: {title: '首页'}
      },
      {
        path: 'blog/:id',
        name: 'blog',
        component: () => import('@/view/Blog'),
        meta: {title: '博客'}
      },
      {
        path: 'tag/:tagName',
        name: 'tag',
        component: () => import('@/view/Tag'),
        meta: {title: '标签'}
      },
      {
        path: 'category/:categoryName',
        name: 'category',
        component: () => import('@/view/Category'),
        meta: {title: '分类'}
      },
      {
        path: 'timeline',
        name: 'timeline',
        component: () => import('@/view/Timeline'),
        meta: {title: '时间轴'}
      },
      {
        path: 'moments',
        name: 'moments',
        component: () => import('@/view/Moments'),
        meta: {title: '动态'}
      },
      {
        path: 'friends',
        name: 'friends',
        component: () => import('@/view/Friends'),
        meta: {title: '邻居们'}
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/view/About'),
        meta: {title: '关于我'}
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  next()
  document.title = to.meta.title + store.state.siteInfo.baseInfo.webTitleSuffix

})
export default router
